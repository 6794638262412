import produce from 'immer';
import { to, toStruct } from '../../../lib/say-it';
import { PrjOrgEffortId } from '../../model';
import {
  FilterOptions,
  IProjectsDirectory,
  IProjectsDirectoryInitializer,
  SortOptions,
} from '../concepts/projects-directory';
import { IProjectsPortfolio } from '../concepts/projects-portfolio';
import { StoreSlice } from '../utils/store-slice';

export const create: StoreSlice<
  IProjectsDirectory & IPrivate & IProjectsDirectoryInitializer,
  IProjectsPortfolio
> = (set, get) => {
  return {
    /** query */
    listAvailableProjects(filterOpts = FilterOptions.all) {
      return get().projects.filter(get().makeProjectsFilter(filterOpts));
    },

    listProjectsAvailableToCompanyUnit(unit) {
      return [];
    },

    readFilterOpts() {
      return get().filterOpts;
    },

    readSortOpts() {
      return get().sortOpts;
    },

    listEffortsByProject(id) {
      return Object.keys(get().projectsOrgsEfforts)
        .filter(PrjOrgEffortId.ifHasProjectId(id))
        .map(
          toStruct({
            unitBudget: PrjOrgEffortId.toUnitBudget,
            workingDays: p => get().projectsOrgsEfforts[p].workingDays,
            economic: p => get().projectsOrgsEfforts[p].economic,
          })
        );
    },

    /** commands */
    initProjectDirectory(data) {
      set(
        produce<IPrivate>(s => {
          s.projects = data.projects;
          s.projectsOrgsEfforts = data.efforts
            .map(
              toStruct({
                key: PrjOrgEffortId.fromStruct,
                workingDays: to('workingDays'),
                economic: to('economic'),
              })
            )
            .reduce(
              (hash, v) => ({
                ...hash,
                [v.key]: {
                  workingDays: v.workingDays,
                  economic: v.economic,
                },
              }),
              {}
            );
        })
      );
    },

    setFilterOpts(opts) {
      set({ filterOpts: opts });
    },

    sortProjects(opts) {
      set(
        produce<IPrivate>(s => {
          s.sortOpts = opts;
          s.projects = s.projects.sort(get().makeProjectsComparator(opts));
        })
      );
    },

    /** private */
    projects: [],

    projectsOrgsEfforts: {},

    sortOpts: SortOptions['by-id'],
    filterOpts: FilterOptions.all,

    makeProjectsFilter(option) {
      switch (option) {
        case FilterOptions.all:
          return () => true;
        case FilterOptions['included-only']:
          return (id: string) => get().isProjectInPortfolio(id);
      }
    },

    makeProjectsComparator(option) {
      switch (option) {
        case SortOptions['by-id']:
          return (a, b) => a.localeCompare(b);
        case SortOptions['excluded-first']:
          return (a, b) => {
            const isAin = get().isProjectInPortfolio(a);
            const isBin = get().isProjectInPortfolio(b);

            if (isAin && isBin) return a.localeCompare(b);
            if (!isAin && !isBin) return a.localeCompare(b);
            if (isAin && !isBin) return 1;
            if (!isAin && isBin) return -1;
          };
        case SortOptions['included-first']:
          return (a, b) => {
            const isAin = get().isProjectInPortfolio(a);
            const isBin = get().isProjectInPortfolio(b);

            if (isAin && isBin) return a.localeCompare(b);
            if (!isAin && !isBin) return a.localeCompare(b);
            if (isAin && !isBin) return -1;
            if (!isAin && isBin) return 1;
          };
      }
    },
  };
};

interface IPrivate {
  projects: string[];
  projectsOrgsEfforts: OrgEffortRecord;
  sortOpts: SortOptions;
  filterOpts: FilterOptions;
  makeProjectsFilter(option: FilterOptions): (id: string) => boolean;
  makeProjectsComparator(option: SortOptions): (a: string, b: string) => number;
}

type OrgEffortRecord = Record<
  string,
  { workingDays: number; economic: number }
>;
