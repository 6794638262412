const PART_SEP = '/';

export const BudgetId = {
  /** queries */
  isInvalidPart: (part: string) => part.includes(PART_SEP),
  isAbsolute: (id: string) => id.startsWith(PART_SEP),
  isRelative: (id: string) => !BudgetId.isAbsolute(id),

  /** factories */
  makeAbsolute: (id: string) => {
    if (BudgetId.isAbsolute(id)) return id;
    return PART_SEP + id;
  },

  toParts: (id: string) => id.split(PART_SEP),

  fromParts: (parts: string[]) => {
    if (parts.some(BudgetId.isInvalidPart))
      throw new Error(`Invalid Parts, ${parts}`);

    return parts.join(PART_SEP);
  },

  toName: (id: string) => BudgetId.toParts(id).reverse()[0],
};
