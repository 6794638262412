import 'bootstrap/dist/css/bootstrap.min.css';
import { Switch, Route, Redirect, Link } from 'wouter';
import { Content, MainLayout, Nav } from './layouts/main';
import { CostCenters } from './pages/cost-centers';
import { Files } from './pages/files';
import { Inbox } from './pages/inbox';
import { Intro } from './pages/intro';
import { MeetingRoom } from './pages/meeting-room';
import { ProjectsPortfolio } from './pages/projects-portfolio';
import { Simulation } from './pages/simulation';
import { pageFrom } from './utils/page';
import { InitializeGameButton } from './widgets/inizialize-game-button';

const page = pageFrom({ Link, Route, Redirect });

const pages = {
  intro: page(Intro, '/intro', 'Intro'),
  meetingRoom: page(MeetingRoom, '/meeting-room', 'Meeting Room'),
  inbox: page(Inbox, '/inbox', 'Inbox'),
  files: page(Files, '/files', 'Files'),
  costCenters: page(CostCenters, '/cost-centers', 'Cost Centers'),
  projectsPortfolio: page(
    ProjectsPortfolio,
    '/projects-portfolio',
    'Projects Portfolio'
  ),
  simulation: page(Simulation, '/simulation', 'Simulation'),
};

export const App = () => {
  return (
    <MainLayout>
      <Nav>
        <MainMenu />
      </Nav>
      <Content>
        <InitializeGameButton />
      </Content>
      <Content>
        <Switch>
          {pages.intro.route()}
          {pages.meetingRoom.route()}
          {pages.inbox.route()}
          {pages.files.route()}
          {pages.projectsPortfolio.route()}
          {pages.costCenters.route()}
          {pages.simulation.route()}
          {pages.intro.redirect()}
        </Switch>
      </Content>
    </MainLayout>
  );
};

const MainMenu = () => (
  <nav>
    <ol>
      {[
        <pages.intro.link />,
        <pages.meetingRoom.link />,
        <pages.inbox.link />,
        <pages.files.link />,
        <pages.projectsPortfolio.link />,
        <pages.costCenters.link />,
        <pages.simulation.link />,
      ].map((c, i) => (
        <li key={i}>{c}</li>
      ))}
    </ol>
  </nav>
);
