import { useStore } from '../../store';
import {
  FilterOptions,
  SortOptions,
} from '../../store/concepts/projects-directory';
import { ProjectDescription } from '../project-description';
import { ProjectInclusionToggle } from '../project-inclusion-toggle';
import { ProjectName } from '../project-name';
import { UILabel } from '../ui-label';

export const PortfolioEditor: React.FC = () => {
  const filterOpts = useStore(s => s.readFilterOpts());
  const projects = useStore(s => s.listAvailableProjects(filterOpts));

  return (
    <>
      <h3>
        <UILabel>Projects</UILabel>
      </h3>
      <div>
        <FilterOptionsSelector />
        <SortOptionsSelector />
      </div>
      <table>
        <thead>
          <tr>
            <th>
              <UILabel>Inc.</UILabel>
            </th>
            <th>
              <UILabel>name</UILabel>
            </th>
            <th>
              <UILabel>desc</UILabel>
            </th>
          </tr>
        </thead>
        <tbody>{projects.map(toProjectRow)}</tbody>
      </table>
    </>
  );
};

const toProjectRow = (id: string, i: number) => (
  <tr key={i}>
    <td>
      <ProjectInclusionToggle id={id} />
    </td>
    <td>
      <ProjectName id={id} />
    </td>
    <td>
      <ProjectDescription id={id} />
    </td>
  </tr>
);

const SortOptionsSelector = () => {
  return (
    <>
      <UILabel>Ordina:</UILabel>
      <SetProjectsSortOptsToById />
      <SetProjectsSortOptsToIncludedFirst />
      <SetProjectsSortOptsToExcludedFirst />
    </>
  );
};

const SetProjectsSortOptsToById = () => {
  const setSortOpts = useStore(s => s.sortProjects);
  return (
    <button onClick={() => setSortOpts(SortOptions['by-id'])}>
      <UILabel>per ID</UILabel>
    </button>
  );
};

const SetProjectsSortOptsToExcludedFirst = () => {
  const setSortOpts = useStore(s => s.sortProjects);
  return (
    <button onClick={() => setSortOpts(SortOptions['excluded-first'])}>
      <UILabel>prima esclusi</UILabel>
    </button>
  );
};

const SetProjectsSortOptsToIncludedFirst = () => {
  const setSortOpts = useStore(s => s.sortProjects);
  return (
    <button onClick={() => setSortOpts(SortOptions['included-first'])}>
      <UILabel>prima inclusi</UILabel>
    </button>
  );
};

const FilterOptionsSelector = () => {
  return (
    <>
      <UILabel>Filtra:</UILabel>
      <SetProjectsFilterToAll />
      <SetProjectsFilterToIncludedOnly />
    </>
  );
};

const SetProjectsFilterToAll = () => {
  const setFilterOpts = useStore(s => s.setFilterOpts);
  return (
    <button onClick={() => setFilterOpts(FilterOptions.all)}>
      <UILabel>Tutti</UILabel>
    </button>
  );
};

const SetProjectsFilterToIncludedOnly = () => {
  const setFilterOpts = useStore(s => s.setFilterOpts);
  return (
    <button onClick={() => setFilterOpts(FilterOptions['included-only'])}>
      <UILabel>Solo inclusi nel portfolio</UILabel>
    </button>
  );
};
