import { useStore } from '../../store';

export const BuyConsultancyWorkingDays: React.FC<{
  unitBudget: string;
}> = props => {
  const buy = useStore(s => s.buyWorkingDays);
  const workingDays = useStore(s =>
    s.toMaxBuyableWorkingDays(props.unitBudget, 10)
  );

  return (
    <button
      disabled={workingDays === 0}
      onClick={() => buy(props.unitBudget, workingDays)}
    >
      + {workingDays.toString().padStart(3, ' ')}
    </button>
  );
};
