import { Nominal } from '../../../../../lib/type-system/nominal';
import { UnitBudgetId, UnitId } from '../../../../model';

export type TransferNoteId = Nominal<string, 'TransferNoteId'>;

export const TransferNoteId = {
  from: (origin: UnitBudgetId, destination: UnitBudgetId) =>
    [origin, destination].join('»') as TransferNoteId,

  toOrigin: (id: TransferNoteId) => UnitBudgetId.fromStr(id.split('»')[0]),
  toDestination: (id: TransferNoteId) => UnitBudgetId.fromStr(id.split('»')[1]),

  toSender: (id: TransferNoteId) =>
    UnitBudgetId.toUnitId(TransferNoteId.toOrigin(id)),
  toReceiver: (id: TransferNoteId) =>
    UnitBudgetId.toUnitId(TransferNoteId.toDestination(id)),

  isSendedBy:
    (sender: UnitId) =>
    (id: TransferNoteId): id is TransferNoteId =>
      TransferNoteId.toSender(id) === sender,

  isReceivedBy:
    (receiver: UnitId) =>
    (id: TransferNoteId): id is TransferNoteId =>
      TransferNoteId.toReceiver(id) === receiver,

  isDestinedTo:
    (destination: UnitBudgetId) =>
    (id: TransferNoteId): id is TransferNoteId =>
      TransferNoteId.toDestination(id) === destination,

  comesFrom:
    (origin: UnitBudgetId) =>
    (id: TransferNoteId): id is TransferNoteId =>
      TransferNoteId.toOrigin(id) === origin,
};
