import { CSSProperties } from 'react';
import { ifValueOf, isEqualTo } from '../../../lib/say-it';
import { marker } from '../../utils/layout';

export const MainLayout: React.FC<{
  children: React.ReactElement[];
}> = props => {
  return (
    <div style={styles.main}>
      <div style={styles.navigation}>
        {props.children.filter(ifValueOf('type', isEqualTo(Nav)))}
      </div>
      <div style={styles.content}>
        {props.children.filter(ifValueOf('type', isEqualTo(Content)))}
      </div>
    </div>
  );
};

export const Nav = marker();
export const Content = marker();

const styles: Record<string, CSSProperties> = {
  main: {
    display: 'flex',
    background: '#eee',
  },
  navigation: {
    minWidth: '200px',
  },
  content: {},
};
