import { ifValueOf } from '../../../lib/say-it';
import { IMapperFn } from '../../../lib/say-it/concepts/mapper';
import { IPredicateFn } from '../../../lib/say-it/concepts/predicate';

export const add = (
  record: Record<string, number>,
  key: string,
  value: number
) => (record[key] = (record[key] || 0) + value);

export const read = (
  record: Record<string, number>,
  key: string,
  initValue: number = 0
) => record[key] || initValue;

export const listEntriesOf = <K extends string | number | symbol, T>(
  record: Record<K, T>
) => {
  return Object.entries(record) as [K, T][];
};

export const ifEntryKey = <K, V, T extends [K, V]>(
  predicate: IPredicateFn<K>
): IPredicateFn<T> => ifValueOf<T, '0'>('0', predicate);

export const toValue =
  <K, V, T extends [K, V]>(): IMapperFn<T, V> =>
  v =>
    v['1'];
