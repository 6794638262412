import React, { Fragment } from 'react';
import {
  SimulationLayout,
  Title,
  Inform,
  Confirm,
} from '../../layouts/simulation';
import { useStore } from '../../store';
import { IAccountingLedgerResults } from '../../store/concepts/accounting-ledger';
import { Accounts } from '../../store/factories/accounting-ledger/accounts';
import { SimulationProjectsList } from '../../widgets/simulation-projects-list';
import { UILabel } from '../../widgets/ui-label';

export const Simulation = () => {
  const simId = '1';

  return (
    <SimulationLayout>
      <Title>
        <h1>Simulation</h1>
      </Title>

      <Inform>
        <SimulationProjectsList id={simId} />
      </Inform>

      <Inform>
        <IncomeStatement simulation={simId} />
      </Inform>

      <Confirm>
        <LaunchSimulationButton simulation={simId} />
      </Confirm>
    </SimulationLayout>
  );
};

const LaunchSimulationButton: React.FC<{ simulation: string }> = props => {
  const launch = useStore(s => s.launchSimulation);
  return (
    <button onClick={() => launch(props.simulation)}>
      <UILabel>Lancia simulazione</UILabel>
    </button>
  );
};

const makeLedgerId = (simulation: string, period: number) =>
  `${simulation}::${period}`;

const useTotalAmount =
  (simulation: string) => (account: Accounts) => (period: number) => {
    return (
      <AccountTotalAmount
        ledger={makeLedgerId(simulation, period)}
        account={account}
      />
    );
  };

const useNCAmount =
  (simulation: string) => (account: Accounts) => (period: number) => {
    return (
      <AccountNonClassifiedAmount
        ledger={makeLedgerId(simulation, period)}
        account={account}
      />
    );
  };

type Entry = [any, IToAmountFn, string?];
interface IToAmountFn {
  (period: number): React.ReactElement;
}

const useAccountingResult =
  (simulation: string) =>
  (accessor: keyof IAccountingLedgerResults) =>
  (period: number) => {
    return (
      <AccountingResultAmount
        ledger={makeLedgerId(simulation, period)}
        accessor={accessor}
      />
    );
  };

const useTotalAmountEntry =
  (totalAmount: (account: Accounts) => IToAmountFn) =>
  (account: Accounts): Entry =>
    [account, totalAmount(account)];

const IncomeStatement: React.FC<{ simulation: string }> = props => {
  const toTotalAmount = useTotalAmount(props.simulation);
  const toNCAmount = useNCAmount(props.simulation);
  const toTotalAmountEntry = useTotalAmountEntry(toTotalAmount);
  const toResAmount = useAccountingResult(props.simulation);

  const periods = [-2, -1, 0, 1, 2, 3];
  const entries: Entry[] = [
    toTotalAmountEntry(Accounts.Revenues),
    toTotalAmountEntry(Accounts.OperatingSalesNet),
    toTotalAmountEntry(Accounts.PharmaSalesGross),
    toTotalAmountEntry(Accounts.CashDiscount),
    toTotalAmountEntry(Accounts.NHA),
    [
      Accounts.Revenues,
      toNCAmount(Accounts.Revenues),
      Accounts.Revenues + '/others',
    ],
    toTotalAmountEntry(Accounts.DirectCosts),
    toTotalAmountEntry(Accounts.CostOfGoodSold),
    ['gross-margin', toResAmount('readGrossMargin')],
    toTotalAmountEntry(Accounts.SalesForceCosts),
    toTotalAmountEntry(Accounts.InternalSalesForce),
    toTotalAmountEntry(Accounts.LeasingSalesForce),
    toTotalAmountEntry(Accounts.MarketingAndDistrCosts),
    toTotalAmountEntry(Accounts.MarketingCosts),
    toTotalAmountEntry(Accounts.DistributionCosts),
    toTotalAmountEntry(Accounts.CommunicationCosts),
    toTotalAmountEntry(Accounts.MedicalCosts),
    toTotalAmountEntry(Accounts.StructMedicalAffairsCosts),
    toTotalAmountEntry(Accounts.StructRAndDCosts),
    toTotalAmountEntry(Accounts.StructRegulatoryAffairsCosts),
    toTotalAmountEntry(Accounts.GenAndAdminExpenses),
    toTotalAmountEntry(Accounts.GeneralManagement),
    toTotalAmountEntry(Accounts.HR),
    toTotalAmountEntry(Accounts.CorporateCommunication),
    toTotalAmountEntry(Accounts.FinanceAndControlling),
    toTotalAmountEntry(Accounts.LegalAndCompliance),
    toTotalAmountEntry(Accounts.IT),
    ['ADJ. EBIT', toResAmount('readAdjustedEBIT')],
    toTotalAmountEntry(Accounts.NonRecurringItems),
    ['EBIT', toResAmount('readEBIT')],
    ['/financial-results', toResAmount('readFinancialResult')],
    toTotalAmountEntry(Accounts.FinancialIncome),
    toTotalAmountEntry(Accounts.FinancialExpenses),
    ['EBT', toResAmount('readEBT')],
    ['Taxes', toResAmount('readTaxes')],
    ['NET INCOME', toResAmount('readNetIncome')],
  ];

  return (
    <table style={{ width: 1200 }}>
      <thead>
        <tr>
          <th rowSpan={2}></th>
          {periods.map((y, i) => (
            <th key={i} colSpan={2}>
              Year {y.toFixed()}
            </th>
          ))}
        </tr>
        <tr>
          {periods.map((y, i) => (
            <Fragment key={i}>
              <th style={{ textAlign: 'center' }}>Th€</th>
              <th style={{ textAlign: 'center' }}>%</th>
            </Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {entries.map(([id, toAmount, label], i) => (
          <tr key={i}>
            <th>{accountLocalName(label || id)}</th>
            {periods.map((p, j) => (
              <Fragment key={j}>
                <td style={{ textAlign: 'right' }}>{toAmount(p)}</td>
                <td style={{ textAlign: 'right', fontSize: '0.7em' }}>- %</td>
              </Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const accountLocalName = (globalName: string) => {
  const [local, ...ancestors] = globalName.split('/').reverse();
  return `${ancestors.map(() => '·').join('')} ${local}`;
};

const AccountTotalAmount: React.FC<{
  ledger: string;
  account: Accounts;
}> = props => {
  const amount = useStore(s =>
    s.readAccountTotalAmount(props.ledger, props.account)
  );
  return <span>{financial(amount)}</span>;
};

const AccountNonClassifiedAmount: React.FC<{
  ledger: string;
  account: Accounts;
}> = props => {
  const amount = useStore(s =>
    s.readAccountNotClassifiedAmount(props.ledger, props.account)
  );
  return <span>{financial(amount)}</span>;
};

const AccountingResultAmount: React.FC<{
  ledger: string;
  accessor: keyof IAccountingLedgerResults;
}> = props => {
  const amount = useStore(s => s[props.accessor](props.ledger));
  return <span>{financial(amount)}</span>;
};

const financial = (amount: number) =>
  `${amount === 0 ? '-' : (amount / 1_000_000).toFixed(2)} €`;
