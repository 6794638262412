export interface IProjectsDirectory {
  listAvailableProjects(filter?: FilterOptions): string[];
  listProjectsAvailableToCompanyUnit(unit: string): string[];
  listEffortsByProject(id: string): OrganizationalEffort[];

  readSortOpts(): SortOptions;
  readFilterOpts(): FilterOptions;

  sortProjects(opts: SortOptions): void;
  setFilterOpts(opts: FilterOptions): void;
}

export enum SortOptions {
  'included-first',
  'excluded-first',
  'by-id',
}

export enum FilterOptions {
  'all',
  'included-only',
}

export type OrganizationalEffort = {
  unitBudget: string;
  workingDays: number;
  economic: number;
};

export interface IProjectsDirectoryInitializer {
  initProjectDirectory(data: {
    projects: string[];
    efforts: Array<Effort>;
  }): void;
}

type Effort = {
  project: string;
  unit: string;
  period: number;
  economic: number;
  workingDays: number;
};
