import { useStore } from '../../store';

export const ProjectInclusionToggle: React.FC<{ id: string }> = props => {
  const isProjectInPortfolio = useStore(s => s.isProjectInPortfolio(props.id));
  const include = useStore(s => s.includeProjectToPortfolio);
  const exclude = useStore(s => s.excludeProjectFromPortfolio);

  return (
    <input
      type="checkbox"
      checked={isProjectInPortfolio}
      onChange={e => {
        if (e.target.checked) include(props.id);
        else exclude(props.id);
      }}
    />
  );
};
