import produce from 'immer';
import { ifValueOf, log, to, toSum } from '../../../../lib/say-it';
import {
  IAccountingLedger,
  IAccountingLedgerResults,
} from '../../concepts/accounting-ledger';
import { StoreSlice } from '../../utils/store-slice';
import { MasterAccounts, Accounts } from './accounts';

export const create: StoreSlice<
  IAccountingLedger<Accounts> & IAccountingLedgerResults & IPrivate
> = (set, get) => {
  return {
    /** queries */

    readAccountTotalAmount(ledgerId: string, account: Accounts) {
      if (!(account in Accounts))
        throw new Error(`Account «${account}» is not defined.`);

      return Object.keys(get().amountsByAccount)
        .filter(key =>
          key.startsWith(accountGlobalKey(ledgerId, account.toString()))
        )
        .map(key => get().amountsByAccount[key])
        .reduce(toSum(), 0);
    },

    readAccountNotClassifiedAmount(ledgerId: string, account: Accounts) {
      if (!(account in Accounts))
        throw new Error(`Account «${account}» is not defined.`);

      const key = accountGlobalKey(ledgerId, account.toString());

      return get().amountsByAccount[key] || 0;
    },

    readGrossMargin(ledgerId: string) {
      return (
        get().readAccountTotalAmount(ledgerId, Accounts.Revenues) -
        get().readAccountTotalAmount(ledgerId, Accounts.DirectCosts)
      );
    },

    readAdjustedEBIT(ledgerId: string) {
      return (
        get().readGrossMargin(ledgerId) -
        get().readAccountTotalAmount(ledgerId, Accounts.IndirectCosts)
      );
    },

    readEBIT(ledgerId: string) {
      return (
        get().readAdjustedEBIT(ledgerId) -
        get().readAccountTotalAmount(ledgerId, Accounts.NonRecurringItems)
      );
    },

    readFinancialResult(ledgerId) {
      return (
        get().readAccountTotalAmount(ledgerId, Accounts.FinancialIncome) -
        get().readAccountTotalAmount(ledgerId, Accounts.FinancialExpenses)
      );
    },

    readEBT(ledgerId: string) {
      return get().readEBIT(ledgerId) + get().readFinancialResult(ledgerId);
    },

    readTaxes(ledgerId: string) {
      return (
        get().readAccountTotalAmount(ledgerId, Accounts.Revenues) *
        get().taxRate
      );
    },

    readNetIncome(ledgerId: string) {
      return get().readEBT(ledgerId) - get().readTaxes(ledgerId);
    },

    /** commands */

    startTransaction(id: string, ledgerId: string, cb) {
      set(
        produce<IPrivate>(s => {
          s.transactions[id] = { ledgerId, reason: '--no-reason--' };
          s.transactionEntries[id] = [];
        })
      );

      cb(
        (account, amount) => {
          get().addTransactionEntry(id, account, amount);
        },
        () => {
          get().commitTransaction(id);
        }
      );
    },

    addTransactionEntry(id: string, account: Accounts, amount: number) {
      if (!get().isTransactionStarted(id))
        throw new Error(
          `Cannot add entry to transaction «${id}» because it is not started.`
        );

      if (!Number.isFinite(amount))
        throw new Error(
          `Cannot add entry «${account}» to transaction «${id}»: amount is not a finite number, got ${amount}`
        );

      if (!(account in Accounts))
        throw new Error(
          `Cannot add entry «${account}» to transaction «${id}»: account is not defined.`
        );

      const info = get().transactions[id];

      set(
        produce<IPrivate>(s => {
          s.transactionEntries[id].push({
            account: accountGlobalKey(info.ledgerId, account.toString()),
            amount,
          });
        })
      );
    },

    commitTransaction(id: string) {
      if (!get().isTransactionStarted(id))
        throw new Error(
          `Cannot commit transaction «${id}» because it is not started.`
        );

      const entries = get().transactionEntries[id] || [];
      const gives = entries
        .filter(ifValueOf('account', get().isAGiveAccount))
        .map(to('amount'))
        .reduce(toSum(), 0);
      const haves = entries
        .filter(ifValueOf('account', get().isAHaveAccount))
        .map(to('amount'))
        .reduce(toSum(), 0);

      if (gives !== haves)
        throw new Error(
          `Cannot commit transaction because it is unbalanced, ` +
            `(gives: ${gives.toFixed(2)}, heaves: ${haves.toFixed(2)})`
        );

      set(
        produce<IPrivate>(s => {
          entries.forEach(entry => {
            s.amountsByAccount[entry.account] =
              (s.amountsByAccount[entry.account] || 0) + entry.amount;
          });

          delete s.transactionEntries[id];
          delete s.transactions[id];
        })
      );
    },

    clearLedger(ledgerId) {
      set(
        produce<IPrivate>(s => {
          Object.keys(s.amountsByAccount)
            .filter(key => key.startsWith(ledgerId))
            .forEach(key => delete s.amountsByAccount[key]);
        })
      );
    },

    /** privates */
    taxRate: 0.075,
    amountsByAccount: {},
    transactions: {},
    transactionEntries: {},

    isTransactionStarted(id) {
      return !!get().transactions[id];
    },

    isAGiveAccount(accountId): accountId is string {
      return [
        MasterAccounts.DirectCosts,
        MasterAccounts.IndirectCosts,
        MasterAccounts.NonRecurringItems,
        MasterAccounts.FinancialExpenses,
        MasterAccounts.Assets,
      ].some(prefix => accountId.startsWith(prefix));
    },

    isAHaveAccount(accountId): accountId is string {
      return [
        MasterAccounts.Revenues,
        MasterAccounts.FinancialIncome,
        MasterAccounts.Liabilities,
      ].some(prefix => accountId.startsWith(prefix));
    },
  };
};

interface IPrivate {
  taxRate: number;
  amountsByAccount: Record<string, number>;
  transactions: Record<string, { ledgerId: string; reason: string }>;
  transactionEntries: Record<
    string,
    Array<{ account: string; amount: number }>
  >;

  isTransactionStarted(id: string): boolean;
  isAGiveAccount(accountId: string): accountId is string;
  isAHaveAccount(accountId: string): accountId is string;
}

const accountGlobalKey = (ledgerId: string, account: string) =>
  [ledgerId, account].join('~');
