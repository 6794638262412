export enum MasterAccounts {
  Revenues = '/revenues',
  DirectCosts = '/direct-costs',
  IndirectCosts = '/indirect-costs',
  NonRecurringItems = '/non-recurring-items',
  FinancialIncome = '/financial-results/financial-income',
  FinancialExpenses = '/financial-results/financial-expenses',
  Assets = '/assets',
  Liabilities = '/liabilities',
}

export enum Accounts {
  Revenues = MasterAccounts.Revenues,
  OperatingSalesNet = Revenues + '/operating-sales-net',
  PharmaSalesGross = OperatingSalesNet + '/pharma-sales-gross',
  CashDiscount = OperatingSalesNet + '/cash-discount-calc',
  NHA = OperatingSalesNet + '/nha-related-sales-deduction',

  DirectCosts = MasterAccounts.DirectCosts,
  CostOfGoodSold = DirectCosts + '/cost-of-good-sold',

  IndirectCosts = MasterAccounts.IndirectCosts,
  SalesForceCosts = IndirectCosts + '/sales-force-costs',
  InternalSalesForce = SalesForceCosts + '/internal-sales-force',
  LeasingSalesForce = SalesForceCosts + '/leasing-sales-force',
  MarketingAndDistrCosts = IndirectCosts + '/marketing-and-distr-costs',
  MarketingCosts = MarketingAndDistrCosts + '/marketing-costs',
  DistributionCosts = MarketingAndDistrCosts + '/distr-costs',
  CommunicationCosts = IndirectCosts + '/communication-costs',
  MedicalCosts = IndirectCosts + '/medical-costs',
  StructMedicalAffairsCosts = MedicalCosts + '/medical-affairs-costs',
  StructRAndDCosts = IndirectCosts + '/r&d-costs',
  StructRegulatoryAffairsCosts = IndirectCosts + '/regulatory-affairs-costs',
  GenAndAdminExpenses = IndirectCosts + '/gen-and-admin-expenses',
  GeneralManagement = GenAndAdminExpenses + '/general-management',
  HR = GenAndAdminExpenses + '/hr',
  CorporateCommunication = GenAndAdminExpenses + '/corporate-communications',
  FinanceAndControlling = GenAndAdminExpenses + '/finance-and-controlling',
  LegalAndCompliance = GenAndAdminExpenses + '/legal-and-compliance',
  IT = GenAndAdminExpenses + '/it',

  NonRecurringItems = MasterAccounts.NonRecurringItems,
  FinancialIncome = MasterAccounts.FinancialIncome,
  FinancialExpenses = MasterAccounts.FinancialExpenses,

  Assets = MasterAccounts.Assets,
  CurrentAssets = Assets + '/current-assets',
  Inventory = CurrentAssets + '/inventory',
  AccountReceivable = CurrentAssets + '/account-receivable',
  TaxCredits = CurrentAssets + '/tax-credits',
  Cash = CurrentAssets + '/cash-and-cash-equivalents',
  FixedAssets = Assets + '/fixed-assets',
  Trademarks = FixedAssets + '/trademarks',
  Goodwill = FixedAssets + '/goodwill',
  FixedAssetsNet = FixedAssets + '/fixed-assets-net',
  OtherLongTermAssets = FixedAssets + '/other-long-term-assets',

  Liabilities = MasterAccounts.Liabilities,
  Equity = Liabilities + '/equity',
  CurrentLiabilities = Liabilities + '/current-liabilities',
  ShortTermLoansPayable = CurrentLiabilities + '/short-term-loans-payable',
  AccountPayable = CurrentLiabilities + '/account-payable',
  IncomeTaxesPayable = CurrentLiabilities + '/income-taxes-payable',
  LongTermLiabilities = Liabilities + '/long-term-liabilities',
  LongTermLoansPayable = LongTermLiabilities + '/long-term-loans-payable',
  RiskProvisions = LongTermLiabilities + '/risk-provisions',
  Retirement = LongTermLiabilities + '/retirement',
}

export type AccountIds = keyof typeof Accounts;
