enum Factor {
  Yotta = 24,
  Zetta = 21,
  Exa = 18,
  Peta = 15,
  Tera = 12,
  Giga = 9,
  Mega = 6,
  Chilo = 3,
  Hecto = 2,
  Deca = 1,
  Zero = 0,
  Deci = -1,
  Centi = -2,
  Milli = -3,
  Micro = -6,
  Nano = -9,
  Pico = -12,
  Femto = -15,
  Atto = -18,
  Zepto = -21,
  Yocto = -24,
}

type Precision =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20;

export const factorSymbol = (factor: Factor) => {
  switch (factor) {
    case Factor.Yotta:
      return 'Y';
    case Factor.Zetta:
      return 'Z';
    case Factor.Exa:
      return 'E';
    case Factor.Peta:
      return 'P';
    case Factor.Tera:
      return 'T';
    case Factor.Giga:
      return 'G';
    case Factor.Mega:
      return 'M';
    case Factor.Chilo:
      return 'k';
    case Factor.Hecto:
      return 'h';
    case Factor.Deca:
      return 'da';
    case Factor.Zero:
      return '';
    case Factor.Deci:
      return 'd';
    case Factor.Centi:
      return 'c';
    case Factor.Milli:
      return 'm';
    case Factor.Micro:
      return 'µ';
    case Factor.Nano:
      return 'n';
    case Factor.Pico:
      return 'p';
    case Factor.Femto:
      return 'f';
    case Factor.Atto:
      return 'a';
    case Factor.Zepto:
      return 'z';
    case Factor.Yocto:
      return 'y';
  }
};

interface INumericFormatterFn {
  (amount: number): string;
}

export const numeric =
  (factor: Factor = 6) =>
  (precision: Precision = 2) => {
    const symbol = factorSymbol(factor);
    return (amount: number) =>
      `${(amount / Math.pow(10, factor)).toFixed(precision)} ${symbol}`;
  };

export const mega = numeric();
export const kilo = numeric(Factor.Chilo);

export const financial = (amount: number, formatter = mega) => {
  if (amount === 0) return '-';
  return `${formatter(2)(amount)}€`;
};

export const price = (amount: number, formatter = mega) => {
  return `${formatter(0)(amount)}€`;
};
