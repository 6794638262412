import {
  Decide,
  Inform,
  PortfolioLayout,
  Title,
  Confirm,
} from '../../layouts/portfolio';
import { BudgetUtilizationAlert } from '../../widgets/budget-utilization-alert';
import { PortfolioEditor } from '../../widgets/portfolio-editor';
import { PortfolioFeasibilityMessage } from '../../widgets/portfolio-feasibility-message';
import { SendProjectsToNextSimulation } from '../../widgets/send-projects-to-next-simulation';
import { UnitsBudgetDashboard } from '../../widgets/units.budget-dashboard';

export const ProjectsPortfolio = () => {
  return (
    <PortfolioLayout>
      <Title>
        <h1>Projects Portfolio</h1>
      </Title>

      <Decide>
        <PortfolioEditor />
      </Decide>

      <Inform>
        <UnitsBudgetDashboard />
      </Inform>

      <Inform>
        <BudgetUtilizationAlert />
      </Inform>

      <Inform>
        <PortfolioFeasibilityMessage />
      </Inform>

      <Confirm>
        <SendProjectsToNextSimulation />
      </Confirm>
    </PortfolioLayout>
  );
};
