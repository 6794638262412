import produce from 'immer';
import { IBudgets, IBudgetsInitializer } from '../concepts/budgets';
import { StoreSlice } from '../utils/store-slice';

export const create: StoreSlice<IBudgets & IPrivate & IBudgetsInitializer> = (
  set,
  get
) => {
  return {
    /** queries */
    readAvailableBudgetFor(entry) {
      return get().budgets[entry] || 0;
    },

    readBudgetLeftFor(entry) {
      return Math.max(
        get().readAvailableBudgetFor(entry) - get().readBudgetSpentFor(entry),
        0
      );
    },

    readBudgetSpentFor(entry) {
      return get().usedBudgets[entry] || 0;
    },

    readRelativeBudgetLeftFor(entry) {
      return 1 - get().usedBudgets[entry] / get().budgets[entry];
    },

    readRelativeBudgetSpentFor(entry) {
      return get().usedBudgets[entry] / get().budgets[entry];
    },

    isBudgetOver(entry) {
      return get().usedBudgets[entry] > get().budgets[entry];
    },

    isSomeBudgetOver(pattern) {
      return get()
        .listBudgetEntries()
        .filter(e => e.startsWith(pattern))
        .some(get().isBudgetOver);
    },

    /** commands */
    decrementBudget(entry, amount) {
      set(
        produce<IPrivate>(s => {
          s.budgets[entry] -= amount;
        })
      );
    },

    incrementBudget(entry, amount) {
      set(
        produce<IPrivate>(s => {
          s.budgets[entry] += amount;
        })
      );
    },

    freeBudget(entry, amount) {
      set(
        produce<IPrivate>(s => {
          s.usedBudgets[entry] -= amount;
        })
      );
    },

    useBudget(entry, activity, amount) {
      set(
        produce<IPrivate>(s => {
          s.usedBudgets[entry] = (s.usedBudgets[entry] || 0) + amount;
          s.activities[entry + activity] = amount;
        })
      );
    },

    initBudgets(data) {
      set(
        produce<IPrivate>(s => {
          s.budgets = data.budgets;
          s.usedBudgets = {};
          s.activities = {};
        })
      );
    },

    /** private */
    budgets: {},
    usedBudgets: {},
    activities: {},

    listBudgetEntries() {
      return Object.keys(get().budgets);
    },
  };
};

interface IPrivate {
  listBudgetEntries(): string[];
  budgets: Record<string, number>;
  usedBudgets: Record<string, number>;
  activities: Record<string, number>;
}
