export interface ICompanyUnits {
  /** queries */
  listCompanyUnits(): string[];
  listOverBudgetCompanyUnits(): string[];

  readAvailableUnitBudget(unitBudget: string): number;
  readUncommittedUnitBudget(unitBudget: string): number;
  readCommittedUnitBudget(unitBudget: string): number;
  readCommittedUnitBudgetToConsultancy(unitBudget: string): number;
  readCommittedUnitBudgetToProjects(unitBudget: string): number;
  readUnitBudgetTransferred(unitBudget: string): number;
  toMaxCommittableAmount(unitBudget: string, amount: number): number;

  readTotalAvailableWorkingDays(unitBudget: string): number;
  readPersonnelWorkingDays(unitBudget: string): number;
  readConsultancyWorkingDays(unitBudget: string): number;
  readMaxBuyableWorkingDays(unitBudget: string): number;
  readMaxSellableWorkingDays(unitBudget: string): number;
  toMaxBuyableWorkingDays(unitBudget: string, workingDaysToBuy: number): number;
  toMaxSellableWorkingDays(
    unitBudget: string,
    workingDaysToSell: number
  ): number;

  readCommittedWorkingDays(unitBudget: string): number;
  readUncommittedWorkingDays(unitBudget: string): number;
  readRelCommittedWorkingDays(unitBudget: string): number;
  readRelUncommittedWorkingDays(unitBudget: string): number;

  isWorkingDaysBudgetOver(unitBudget: string): boolean;

  readAvailableBudgetOnProjects(unitBudget: string): number;
  readCommittedBudgetOnProjects(unitBudget: string): number;
  readUncommittedBudgetOnProjects(unitBudget: string): number;
  readRelCommittedBudgetOnProjects(unitBudget: string): number;
  readRelUncommittedBudgetOnProjects(unitBudget: string): number;

  isBudgetOnProjectsOver(unitBudget: string): boolean;

  moneyToWorkingDays(money: number): { workingDays: number; change: number };
  workingDaysToMoney(workingDays: number): number;

  /** commands */

  commitProjectsUnitBudget(
    unitBudget: string,
    activity: string,
    effort: Effort
  ): void;
  releaseProjectsUnitBudget(unitBudget: string, effort: Effort): void;
  transferUnitBudget(fromUnit: string, toUnit: string, amount: number): void;

  changeMoneyWithWorkingDays(unitBudget: string, money: number): void;
  buyWorkingDays(unitBudget: string, workingDays: number): void;
  sellWorkingDays(unitBudget: string, workingDays: number): void;

  commitUnitBudgetToProjects(unitBudget: string, amount: number): void;
  releaseUnitBudgetFromProjects(unitBudget: string, amount: number): void;

  // PRC = Product Related Costs
  increasePRCBudget(amount: string): void;
  decreasePRCBudget(amount: string): void;
}

export enum BudgetKind {
  Economic = 'economic',
  WorkingDays = 'working-days',
}

type Effort = Record<BudgetKind, number>;

export interface ICompanyUnitsInitializer {
  initCompanyUnits(data: {
    personnelWorkingDays: Record<string, number>;
    availableUnitBudgets: Record<string, number>;
  }): void;
}
