import { IPlayer } from '../concepts/player';
import { StoreSlice } from '../utils/store-slice';

export const create: StoreSlice<IPlayer & IPrivate> = (set, get) => {
  return {
    gameId: 'G1',
    playerId: 'P1',
  };
};

interface IPrivate {}
