import { CSSProperties } from 'react';
import { ifValueOf, isEqualTo } from '../../../lib/say-it';
import { Layout, marker } from '../../utils/layout';

export const SimulationLayout: Layout = props => {
  return (
    <div style={styles.main}>
      <div style={styles.title}>
        {props.children.filter(ifValueOf('type', isEqualTo(Title)))}
      </div>
      <div style={styles.center}>
        <div style={styles.decide}>
          {props.children.filter(ifValueOf('type', isEqualTo(Decide)))}
        </div>
        <div style={styles.inform}>
          {props.children.filter(ifValueOf('type', isEqualTo(Inform)))}
        </div>
      </div>
      <div style={styles.confirm}>
        <h6>Confirm</h6>
        {props.children.filter(ifValueOf('type', isEqualTo(Confirm)))}
      </div>
    </div>
  );
};

export const Title = marker();
export const Decide = marker();
export const Inform = marker();
export const Confirm = marker();

const styles: Record<string, CSSProperties> = {
  main: {
    display: 'flex',
    background: '#eee',
    flexDirection: 'column',
  },
  center: {
    display: 'flex',
  },

  decide: {
    minWidth: '320px',
  },
  inform: {},
  confirm: {},
};
