import produce from 'immer';
import { toSum } from '../../../lib/say-it';
import { IBudgets } from '../concepts/budgets';
import { ICompanyUnits } from '../concepts/company-units';
import {
  ICostCentres,
  ICostCentresInitializer,
} from '../concepts/cost-centres';
import { StoreSlice } from '../utils/store-slice';

export const create: StoreSlice<
  ICostCentres & IPrivate & ICostCentresInitializer,
  ICompanyUnits & IBudgets
> = (set, get) => {
  return {
    /** queries */

    readCCBudgetAmount(costCentre) {
      return get().costCenterBudget(costCentre);
    },
    readCCBudgetSaving(costCentre) {
      return 0;
    },
    readCCBudgetReceived(costCentre) {
      return 0;
    },

    isCCAccountDiscretional(id) {
      const [accountId] = id.split('::');
      return get().costCenters()[accountId] === CostCenterKind.Discretional;
    },

    listCCAccounts(unit) {
      return Object.keys(get().costCenters()).filter(k => k.startsWith(unit));
    },

    readCCAccountValue(id) {
      return get().costCentersAccountsAmount[id] || 0;
    },

    readCCAccountSaving(id) {
      return Math.max(
        0,
        get().costCentersAccountsReference[id] - get().readCCAccountValue(id)
      );
    },

    /** commands */
    setCCAccountValue(id, amount) {
      const [account, period] = id.split('::');
      const [centre] = account.split('/');
      const total = get().totalCostCenterAmount(centre, Number(period));
      const max = get().costCenterBudget(centre);
      const delta = amount - get().readCCAccountValue(id);

      const correction = Math.min(0, max - (total + delta));

      set(
        produce<IPrivate>(s => {
          s.costCentersAccountsAmount[id] = amount + correction;
        })
      );

      const savings = get().readCCAccountSaving(id);
      get().decrementBudget(`${centre}/time`, Math.round(savings / 10000));
    },

    moveCCBudget(from, to, amount) {},

    initCostCenters(data) {
      set(
        produce<IPrivate>(s => {
          s.costCenterBudgets = data.budgets;
        })
      );
    },

    /** private */
    costCenterBudgets: {},

    costCenters() {
      return get()
        .listCompanyUnits()
        .map(toUnitAccounts)
        .concat([commercialExtraAccounts])
        .reduce((acc, u) => ({ ...acc, ...u }), {});
    },

    costCentersAccountsAmount: {
      [`commercial/personnel-costs::-2`]: 500_000,
    },

    costCentersAccountsReference: {
      [`commercial/consultancy::1`]: 100_000,
    },

    costCenterBudget(costCentre) {
      return get().costCenterBudgets[costCentre] || 0;
    },

    totalCostCenterAmount(unit, period) {
      return Object.keys(get().costCentersAccountsAmount)
        .filter(k => {
          const [account, p] = k.split('::');
          return account.startsWith(unit) && Number(p) === period;
        })
        .map(k => get().costCentersAccountsAmount[k])
        .reduce(toSum(), 0);
    },
  };
};

interface IPrivate {
  costCenters(): Record<string, CostCenterKind>;
  costCenterBudgets: Record<string, number>;
  costCentersAccountsAmount: Record<string, number>;
  costCentersAccountsReference: Record<string, number>;
  costCenterBudget(costCentre: string): number;
  totalCostCenterAmount(unit: string, period: number): number;
}

enum CostCenterKind {
  Discretional = 'discretional',
  NonDiscretional = 'non-discretional',
}

const toUnitAccounts = (u: string): Record<string, CostCenterKind> => ({
  [`${u}/personnel-costs`]: CostCenterKind.NonDiscretional,
  [`${u}/travel-expenses`]: CostCenterKind.NonDiscretional,
  [`${u}/total-costs-for-NEW-PROJECTS`]: CostCenterKind.Discretional,
  [`${u}/postage-phone-expenses`]: CostCenterKind.NonDiscretional,
  [`${u}/other-personnel-expenses`]: CostCenterKind.NonDiscretional,
  [`${u}/consultancy`]: CostCenterKind.Discretional,
  [`${u}/maintenance-repairs `]: CostCenterKind.NonDiscretional,
  [`${u}/depreciation `]: CostCenterKind.NonDiscretional,
  [`${u}/other expenses `]: CostCenterKind.NonDiscretional,
  [`${u}/existing-products-related-costs`]: CostCenterKind.Discretional,
  [`${u}/secondary-costs`]: CostCenterKind.NonDiscretional,
});

const commercialExtraAccounts: Record<string, CostCenterKind> = {
  [`commercial/products`]: CostCenterKind.NonDiscretional,
  [`commercial/products/samples`]: CostCenterKind.Discretional,
  [`commercial/products/events`]: CostCenterKind.Discretional,
  [`commercial/products/printings`]: CostCenterKind.Discretional,
  [`commercial/products/market-research`]: CostCenterKind.Discretional,
  [`commercial/products/maintenance-of-registration`]:
    CostCenterKind.NonDiscretional,
  [`commercial/products/other-prc`]: CostCenterKind.NonDiscretional,
};
