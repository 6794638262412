import React from 'react';

export type Page = {
  link: React.FC;
  route: () => React.ReactElement;
  redirect: () => React.ReactElement;
};

export const pageFrom = (deps: {
  Link: React.FC<{ to: string; children: React.ReactNode }>;
  Route: React.FC<{ path: string; component: React.FC }>;
  Redirect: React.FC<{ to: string }>;
}) => {
  const usedPaths: string[] = [];

  return (comp: React.FC, path: string, title: string): Page => {
    if (usedPaths.includes(path))
      throw new Error(`Path ${path} was assigned already.`);

    usedPaths.push(path);

    return {
      link: () => <deps.Link to={path}>{title}</deps.Link>,
      route: () => <deps.Route path={path} component={comp} />,
      redirect: () => <deps.Redirect to={path} />,
    };
  };
};
