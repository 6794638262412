import produce from 'immer';
import { to, toSum } from '../../../../lib/say-it';
import { UnitBudgetId } from '../../../model';
import { ICompanyUnits } from '../../concepts/company-units';
import {
  IUnitBudgetTransfers,
  IUnitBudgetTransfersInitializer,
} from '../../concepts/unit-budget-transfers';
import {
  add,
  ifEntryKey,
  listEntriesOf,
  read,
} from '../../utils/state-operations';
import { StoreSlice } from '../../utils/store-slice';
import { TransferNoteId } from './values/transfer-note-id';

export const create: StoreSlice<
  IUnitBudgetTransfers & IPrivate & IUnitBudgetTransfersInitializer,
  ICompanyUnits
> = (set, get) => {
  return {
    initUnitBudgetTransfers() {
      set({
        ...emptyState(),
      });
    },

    /** queries */

    readUnitBudgetReceivedFrom(destination, sender): number {
      return listEntriesOf(get().transferNotes)
        .filter(ifEntryKey(TransferNoteId.isSendedBy(sender)))
        .filter(ifEntryKey(TransferNoteId.isDestinedTo(destination)))
        .map(to('1'))
        .reduce(toSum(), 0);
    },

    readUnitBudgetTransferredTo(origin, receiver): number {
      return listEntriesOf(get().transferNotes)
        .filter(ifEntryKey(TransferNoteId.isReceivedBy(receiver)))
        .filter(ifEntryKey(TransferNoteId.comesFrom(origin)))
        .map(to('1'))
        .reduce(toSum(), 0);
    },

    readUnitBudgetSendableAmount(origin) {
      return 1_000_000;
    },

    readUnitBudgetTotalTransferredIn(destination) {
      return listEntriesOf(get().transferNotes)
        .filter(ifEntryKey(TransferNoteId.isDestinedTo(destination)))
        .map(to('1'))
        .reduce(toSum(), 0);
    },

    readUnitBudgetTotalTransferredOut(origin) {
      return listEntriesOf(get().transferNotes)
        .filter(ifEntryKey(TransferNoteId.comesFrom(origin)))
        .map(to('1'))
        .reduce(toSum(), 0);
    },

    readUnitBudgetSendableAmountLeft(origin) {
      return (
        get().readUnitBudgetSendableAmount(origin) -
        get().readUnitBudgetTotalTransferredIn(origin)
      );
    },

    /** commands */
    sendUnitBudget(sender, receiver, period, amount) {
      const origin = UnitBudgetId.from(sender, period);
      const destination = UnitBudgetId.from(receiver, period);
      const id = TransferNoteId.from(origin, destination);

      const v = Math.min(
        amount,
        get().readUnitBudgetSendableAmountLeft(origin)
      );

      set(
        produce<IPrivate>(s => {
          add(s.transferNotes, id, v);
        })
      );
    },

    reclaimUnitBudget(senderUnit, receiverUnit, period, amount) {
      const sender = UnitBudgetId.from(senderUnit, period);
      const receiver = UnitBudgetId.from(receiverUnit, period);
      const id = TransferNoteId.from(sender, receiver);

      const v = Math.min(amount, read(get().transferNotes, id));

      set(
        produce<IPrivate>(s => {
          add(s.transferNotes, id, -v);
        })
      );
    },

    /** privates */
    ...emptyState(),
  };
};

const emptyState = (): IPrivate => ({
  transferNotes: {},
});

interface IPrivate {
  transferNotes: Record<TransferNoteId, number>;
}
