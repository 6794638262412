import { Nominal } from '../../../lib/type-system/nominal';
import { Period } from './period';
import { UnitId } from './unit-id';

export type UnitBudgetId = Nominal<string, 'UnitBudgetId'>;

const PART_SEP = '::';
export const UnitBudgetId = {
  fromStr: (plain: string) => {
    return plain as UnitBudgetId;
  },

  from: (unit: string, period: number) =>
    [unit, period].join(PART_SEP) as UnitBudgetId,

  fromStruct: (s: UnitBudgetIdStruct) => UnitBudgetId.from(s.unit, s.period),

  toUnitId: (id: string) => id.split(PART_SEP)[0] as UnitId,
  toPeriod: (id: string) => Number(id.split(PART_SEP)[1]) as Period,

  isEqual: (a: UnitBudgetId) => (b: UnitBudgetId) => a === b,
};

type UnitBudgetIdStruct = {
  unit: string;
  period: number;
};
