import { useStore } from '../../store';

export const InitializeGameButton: React.FC = props => {
  const init = useStore(s => s.init);

  return (
    <button onClick={() => init({ gameId: 'G1', playerId: 'P1' })}>
      Inizializza Partita
    </button>
  );
};
