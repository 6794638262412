import create, { StateCreator, StoreApi } from 'zustand';

export class StoreBuilder<T> {
  static summon() {
    return new StoreBuilder<unknown>(() => {
      return {};
    });
  }

  constructor(private readonly creator: StateCreator<T>) {}

  append<nT>(slice: StateCreator<nT>) {
    return new StoreBuilder<T & nT>((set, get, api) => ({
      ...this.creator(set, get as () => T, api as StoreApi<T>),
      ...slice(set, get as () => nT, api as StoreApi<nT>),
    }));
  }

  toRootSlice() {
    return (
      set: StoreApi<T>['setState'],
      get: StoreApi<T>['getState'],
      api: StoreApi<T>
    ) => this.creator(set, get, api);
  }

  make() {
    return create(this.creator);
  }
}
