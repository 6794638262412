import produce from 'immer';
import {
  IPositionPrimaryMetrics,
  IPositionSecondaryMetrics,
} from '../concepts';

const emptyState = () => ({
  totalVolume: 0,
  retailPrice: 0,
  hospitalPrice: 0,
  mix: 0,
  nonClassifiedPharmaSalesGross: 0,
  cashDiscount: 0,
  nha: 0,
  unitCogs: 0,
});

export class PositionMetrics implements IPositionSecondaryMetrics {
  static from(plain: Partial<State>) {
    return new PositionMetrics({ ...emptyState(), ...plain });
  }

  static empty() {
    return new PositionMetrics();
  }

  constructor(private readonly state: State = emptyState()) {}

  /** queries */

  read(key: keyof State) {
    return this.state[key];
  }

  hospitalMix() {
    return 1 - this.state.mix;
  }

  retailMix() {
    return this.state.mix;
  }

  hospitalVolumes() {
    return this.state.totalVolume * this.hospitalMix();
  }

  retailVolumes() {
    return this.state.totalVolume * this.retailMix();
  }

  averagePricePerPack() {
    return (
      this.retailMix() * this.state.retailPrice +
      this.hospitalMix() * this.state.hospitalPrice
    );
  }

  pharmaTotalSalesGross() {
    return (
      this.state.nonClassifiedPharmaSalesGross +
      this.hospitalVolumes() * this.state.hospitalPrice +
      this.retailVolumes() * this.state.retailPrice
    );
  }

  operatingSalesNet() {
    return (
      this.pharmaTotalSalesGross() - this.state.cashDiscount - this.state.nha
    );
  }

  totalCogs() {
    return this.state.totalVolume * this.state.unitCogs;
  }

  grossMargin() {
    return this.operatingSalesNet() - this.totalCogs();
  }

  /** commands */

  set(key: keyof State, value: number) {
    return new PositionMetrics(
      produce(this.state, s => {
        s[key] = value;
      })
    );
  }

  add(target: PositionMetrics) {
    return new PositionMetrics(
      produce(this.state, s => {
        Object.keys(this.state).forEach(
          k => (s[k] += target.read(k as keyof IPositionPrimaryMetrics))
        );
      })
    );
  }
}

type State = IPositionPrimaryMetrics;
