import { useStore } from '../../store';

export const BudgetUtilizationAlert = () => {
  const overBudgetUnits = useStore(s => s.listOverBudgetCompanyUnits());

  return (
    <ul>
      {overBudgetUnits.map((unit, i) => (
        <li key={i}>
          attenzione, la funzione <b>«{unit}»</b> non sarà in grado di portare a
          termine tutti i progetti in portafoglio
        </li>
      ))}
    </ul>
  );
};
