import { useStore } from '../../store';

export const ConsultancyWorkingDays: React.FC<{
  unitBudget: string;
}> = props => {
  const workingDays = useStore(s =>
    s.readConsultancyWorkingDays(props.unitBudget)
  );
  return <span>{workingDays}</span>;
};
