import React from 'react';
import {
  Bar,
  BarChart,
  LabelList,
  LabelProps,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { toStruct } from '../../../lib/say-it';
import { UnitBudgetId } from '../../model';
import { useStore } from '../../store';

enum Dims {
  EconomicCommitted = 'economic-committed',
  EconomicUncommitted = 'economic-uncommitted',
  EconomicIsOver = 'economic-is-over',
  WorkingDaysCommitted = 'working-days-committed',
  WorkingDaysUncommitted = 'working-days-uncommitted',
  WorkingDaysIsOver = 'working-days-is-over',
}

export const Unit_BudgetUtilization: React.FC<{
  unit: string;
}> = props => {
  const periods = useStore(s => s.listFuturePeriods());

  const data = useStore(s =>
    periods
      .map(p => UnitBudgetId.from(props.unit, p))
      .map(
        toStruct({
          id: UnitBudgetId.toPeriod,
          [Dims.EconomicCommitted]: s.readRelCommittedBudgetOnProjects,
          [Dims.EconomicUncommitted]: s.readRelUncommittedBudgetOnProjects,
          [Dims.EconomicIsOver]: s.isBudgetOnProjectsOver,
          [Dims.WorkingDaysCommitted]: s.readRelCommittedWorkingDays,
          [Dims.WorkingDaysUncommitted]: s.readRelUncommittedWorkingDays,
          [Dims.WorkingDaysIsOver]: s.isWorkingDaysBudgetOver,
        })
      )
  );

  return (
    <ResponsiveContainer width={'100%'} height={150}>
      <BarChart
        data={data}
        margin={{
          top: 40,
          right: 5,
          left: 5,
          bottom: 5,
        }}
        compact
        barSize={20}
      >
        <XAxis dataKey={'id'} />
        <YAxis domain={[0, 1]} />
        {/* <Legend layout="vertical" align="right" alignmentBaseline="middle" /> */}

        <Bar
          dataKey={Dims.EconomicCommitted}
          stackId="economic"
          fill="red"
          name="€ budget, committed"
        >
          <LabelList
            dataKey={Dims.EconomicIsOver}
            position="top"
            content={renderOverBudgetLabel}
          />
        </Bar>

        <Bar
          dataKey={Dims.WorkingDaysCommitted}
          stackId="working-days"
          fill="blue"
          name="w.d. budget, committed"
        >
          <LabelList
            dataKey={Dims.WorkingDaysIsOver}
            position="top"
            content={renderOverBudgetLabel}
          />
        </Bar>

        <Bar
          dataKey={Dims.EconomicUncommitted}
          stackId="economic"
          fill="pink"
          name="€ budget, uncommitted"
        />

        <Bar
          dataKey={Dims.WorkingDaysUncommitted}
          stackId="working-days"
          fill="lightblue"
          name="w.d. budget, uncommitted"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const renderOverBudgetLabel = (props: LabelProps) => {
  const x = Number(props.x);
  const y = Number(props.y);
  const width = Number(props.width);
  const radius = 10;

  if (!props.value) return null;

  return (
    <g transform="translate(0,-5)">
      <circle cx={x + width / 2} cy={y - radius} r={radius} fill="orange" />
      <text
        x={x + width / 2}
        y={y - radius}
        fill="black"
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="bold"
      >
        ⚠
      </text>
    </g>
  );
};

const key =
  <T, K extends keyof T>(target: T[]) =>
  (k: K) =>
    k;
