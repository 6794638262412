import { useStore } from '../../store';

export const SendProjectsToNextSimulation: React.FC = props => {
  const nextSimulationId = useStore(s => s.readNextSimulationId());
  const send = useStore(s => s.sendProjectsToSimulation);
  const listProjects = useStore(s => s.listProjectsInPortfolio);

  return (
    <button
      onClick={() => send(nextSimulationId, listProjects())}
      title="Invia i progetti inseriti nel portfolio al simulatore"
    >
      Prepara simulazione
    </button>
  );
};
