import { useStore } from '../../store';
import { financial, mega } from '../../utils/formatters';

export const CommittedUnitBudgetOnProjects: React.FC<{
  unitBudget: string;
}> = props => {
  const amount = useStore(s =>
    s.readAvailableBudgetOnProjects(props.unitBudget)
  );
  return <span>{financial(amount, mega)}</span>;
};
