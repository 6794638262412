import produce from 'immer';
import { identity, log, toStruct } from '../../../../lib/say-it';
import { IAccountingLedger } from '../../concepts/accounting-ledger';
import { ISimulations } from '../../concepts/simulations';
import { StoreSlice } from '../../utils/store-slice';
import { Accounts } from '../accounting-ledger/accounts';
import {
  IPositionPrimaryMetrics,
  OperationImpactOnCompany,
  OperationImpactOnPosition,
} from './concepts';
import { PositionMetrics } from './values/position-metrics';

export const create: StoreSlice<
  ISimulations & IPrivate,
  IAccountingLedger<Accounts>
> = (set, get) => {
  return {
    /** queries */

    listSimulations() {
      return [];
    },

    isSimulationReady(id) {
      return false;
    },

    listProjectsInSimulation(id) {
      return get().projectsInSimulations[id] || [];
    },

    readNextSimulationId() {
      return '1';
    },

    /** commands */
    prepareSimulation(id) {},

    sendProjectsToSimulation(id, projects) {
      set(
        produce<IPrivate>(s => {
          s.projectsInSimulations[id] = projects;
        })
      );
    },

    launchSimulation(id) {
      const projects = get().projectsInSimulations[id];
      const periods = [-2, -1, 0, 1, 2, 3];
      const products = ['alfa', 'beta', 'gamma', 'delta'];
      const markets = [
        'europe',
        'north-america',
        'latin-america',
        'partners-business',
      ];

      const metricsByPeriod: Record<number, PositionMetrics[]> = {};

      for (const market of markets) {
        for (const product of products) {
          periods
            .reduce(
              (results, period) => {
                const position = { market, product, period };

                const exogenous = PositionMetrics.from(
                  get().exogenousImpactOnPositions()[
                    [market, product, period].join('::')
                  ]
                );

                const metric = projects
                  .map(get().toOperationImpactOnPosition(position))
                  .map(PositionMetrics.from)
                  .reduce(
                    (acc, pos) => acc.add(pos),
                    results[results.length - 1].metric.add(exogenous)
                  );

                return [...results, { id: period, metric }];
              },
              [
                {
                  id: 'first',
                  metric: get().readInitialPositionMetrics(market, product),
                },
              ]
            )
            .forEach(result => {
              metricsByPeriod[result.id] = metricsByPeriod[result.id] || [];
              metricsByPeriod[result.id].push(result.metric);
            });
        }
      }

      periods
        .map(
          toStruct({
            period: identity(),
            total: period =>
              metricsByPeriod[period].reduce(
                (total, metric) => total.add(metric),
                PositionMetrics.empty()
              ),
          })
        )
        .forEach(({ period, total }) => {
          const ledgerId = [id, period].join('::');
          get().clearLedger(ledgerId);

          get().startTransaction('1', ledgerId, (record, commit) => {
            record(Accounts.PharmaSalesGross, total.pharmaTotalSalesGross());
            record(Accounts.CashDiscount, total.read('cashDiscount'));
            record(Accounts.NHA, total.read('nha'));
            record(Accounts.CostOfGoodSold, total.totalCogs());

            record(
              Accounts.Cash,
              total.pharmaTotalSalesGross() +
                total.read('cashDiscount') +
                total.read('nha') -
                total.totalCogs()
            );

            commit();
          });
        });
    },

    /** privates */
    projectsInSimulations: {},
    projectsImpactsOnCompany: {},
    projectsImpactsOnPosition: () => ({
      'prj.001::europe::alfa::1': {
        mix: 0.05,
      },
      'prj.001::europe::alfa::2': {
        mix: 0.02,
      },
      'prj.001::europe::alfa::3': {
        mix: 0.02,
      },
      'prj.000::europe::alfa::1': {
        unitCogs: 1,
      },
    }),
    initialPositions: {
      'europe::alfa': {
        totalVolume: 28_000_000,
        retailPrice: 26.97,
        hospitalPrice: 7.5,
        mix: 0.9,
        cashDiscount: 45_000_000,
        nha: 22_500_000,
        unitCogs: 5.865,
      },
    },

    exogenousImpactOnPositions() {
      return {
        'europe::alfa::1': {
          totalVolume: -5_000_000,
        },
      };
    },

    readInitialPositionMetrics(market, product) {
      return PositionMetrics.from(
        get().initialPositions[[market, product].join('::')] || {}
      );
    },

    toOperationImpactOnCompany(period) {
      return projectId => ({
        nonRecurringItems: 0,
        financialIncome: 0,
        financialExpenses: 0,
      });
    },

    toOperationImpactOnPosition(position) {
      return projectId =>
        get().projectsImpactsOnPosition()[
          [projectId, position.market, position.product, position.period].join(
            '::'
          )
        ] || {};
    },
  };
};

interface IPrivate {
  projectsInSimulations: Record<string, string[]>;

  projectsImpactsOnCompany: Record<string, OperationImpactOnCompany>;
  projectsImpactsOnPosition: () => Record<string, OperationImpactOnPosition>;
  initialPositions: Record<string, Partial<IPositionPrimaryMetrics>>;
  exogenousImpactOnPositions(): Record<
    string,
    Partial<IPositionPrimaryMetrics>
  >;

  readInitialPositionMetrics(market: string, product: string): PositionMetrics;

  toOperationImpactOnCompany(
    period: number
  ): (id: string) => OperationImpactOnCompany;

  toOperationImpactOnPosition(position: {
    period: number;
    market: string;
    product: string;
  }): (id: string) => OperationImpactOnPosition;
}
