const PART_SEP = '/';

export const AccountId = {
  /** queries */
  isInvalidPart: (part: string) => part.includes(PART_SEP),
  isAbsolute: (id: string) => id.startsWith(PART_SEP),
  isRelative: (id: string) => !AccountId.isAbsolute(id),

  /** factories */
  makeAbsolute: (id: string) => {
    if (AccountId.isAbsolute(id)) return id;
    return PART_SEP + id;
  },

  toParts: (id: string) => id.split(PART_SEP),

  fromParts: (parts: string[]) => {
    if (parts.some(AccountId.isInvalidPart))
      throw new Error(`Invalid Parts, ${parts}`);

    return parts.join(PART_SEP);
  },

  toName: (id: string) => AccountId.toParts(id).reverse()[0],
};
