import { Fragment } from 'react';
import { UnitBudgetId } from '../../model';
import { useStore } from '../../store';
import { AvailableUnitBudget } from '../unit-budget/unit-budget.available';
import { UncommittedUnitBudget } from '../unit-budget/unit-budget.uncommitted';
import { BuyConsultancyWorkingDays } from '../unit-budget/consultancy-working-days.buy';
import { CommittedUnitBudget } from '../unit-budget/unit-budget-committed';
import { CommittedUnitBudgetOnConsultancy } from '../unit-budget/consultancy.committed';
import { ConsultancyWorkingDays } from '../unit-budget/consultancy-working-days';
import { PersonnelWorkingDays } from '../unit-budget/working-days.personnel';
import { SellConsultancyWorkingDays } from '../unit-budget/consultancy-working-days.sell';
import { AvailableWorkingDays } from '../unit-budget/working-days.available';
import { CommittedWorkingDays } from '../unit-budget/working-days.committed';
import { CommittedUnitBudgetOnProjects } from '../unit-budget/projects.committed';
import { UncommittedWorkingDays } from '../unit-budget/working-days.uncommitted';
import { UnitBudgetReceivedFrom } from '../unit-budget/unit-budget.received-from';
import { UnitBudgetTransferredTo } from '../unit-budget/unit-budget.transferred-to';
import { SendUnitBudget } from '../unit-budget/unit-budget.send';
import { ReclaimUnitBudget } from '../unit-budget/unit-budget.reclaim';
import { ifNot, isEqualTo, toStruct } from '../../../lib/say-it';
import { TransferableUnitBudget } from '../unit-budget/unit-budget.transferable';
import { TransferredUnitBudget } from '../unit-budget/unit-budget.transferred';
import { NotTransferredUnitBudget } from '../unit-budget/unit-budget.not-transferred';

export const Unit_BudgetTable: React.FC<{ unit: string }> = props => {
  const periods = useStore(s => s.listFuturePeriods());
  const units = useStore(s =>
    s.listCompanyUnits().filter(ifNot(isEqualTo(props.unit)))
  );

  return (
    <table style={{ width: '100%', fontSize: '0.9em' }}>
      <thead>
        <tr>
          <th></th>
          {periods.map((p, i) => (
            <Fragment key={i}>
              <th style={{ textAlign: 'right' }}>{p}</th>
            </Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th
            colSpan={1 + periods.length}
            style={{ textAlign: 'center', background: '#ddd' }}
          >
            TRANSFERS
          </th>
        </tr>

        <tr>
          <th>Transferable</th>
          <UnitBudgetCells unit={props.unit} value={TransferableUnitBudget} />
        </tr>

        <tr>
          <th>Transferred</th>
          <UnitBudgetCells unit={props.unit} value={TransferredUnitBudget} />
        </tr>

        <tr>
          <th>Not Transferred</th>
          <UnitBudgetCells unit={props.unit} value={NotTransferredUnitBudget} />
        </tr>

        {units
          .map(
            toStruct({
              sender: v => v,
              received: UnitBudgetReceivedFrom,
              transferred: UnitBudgetTransferredTo,
              send: SendUnitBudget,
              reclaim: ReclaimUnitBudget,
            })
          )
          .map((v, i) => (
            <Fragment key={i}>
              <tr>
                <th colSpan={1 + periods.length}>· {v.sender.toUpperCase()}</th>
              </tr>
              <tr>
                <th>·· from</th>
                <UnitBudgetCells unit={props.unit} value={v.received} />
              </tr>
              <tr>
                <th>·· to</th>
                <UnitBudgetCells unit={props.unit} value={v.transferred} />
              </tr>
              <tr>
                <th>··· send</th>
                <UnitBudgetCells unit={props.unit} value={v.send} />
              </tr>
              <tr>
                <th>··· reclaim</th>
                <UnitBudgetCells unit={props.unit} value={v.reclaim} />
              </tr>
            </Fragment>
          ))}

        <tr>
          <th
            colSpan={1 + periods.length}
            style={{ textAlign: 'center', background: '#ddd' }}
          >
            UNIT BUDGET
          </th>
        </tr>

        <tr>
          <th>Available</th>
          <UnitBudgetCells unit={props.unit} value={AvailableUnitBudget} />
        </tr>

        <tr>
          <th>Committed</th>
          <UnitBudgetCells unit={props.unit} value={CommittedUnitBudget} />
        </tr>

        <tr>
          <th>·· to NEW projects</th>
          <UnitBudgetCells
            unit={props.unit}
            value={CommittedUnitBudgetOnProjects}
          />
        </tr>

        <tr>
          <th>·· to consultancy</th>
          <UnitBudgetCells
            unit={props.unit}
            value={CommittedUnitBudgetOnConsultancy}
          />
        </tr>

        <tr>
          <th>Uncommitted</th>
          <UnitBudgetCells unit={props.unit} value={UncommittedUnitBudget} />
        </tr>

        <tr>
          <th
            colSpan={1 + periods.length}
            style={{ textAlign: 'center', background: '#ddd' }}
          >
            WORKING DAYS
          </th>
        </tr>

        <tr>
          <th>Available</th>
          <UnitBudgetCells unit={props.unit} value={AvailableWorkingDays} />
        </tr>

        <tr>
          <th>·· from Personnel</th>
          <UnitBudgetCells unit={props.unit} value={PersonnelWorkingDays} />
        </tr>

        <tr>
          <th>·· from Consultancy</th>
          <UnitBudgetCells unit={props.unit} value={ConsultancyWorkingDays} />
        </tr>

        <tr>
          <th>··· buy</th>
          <UnitBudgetCells
            unit={props.unit}
            value={BuyConsultancyWorkingDays}
          />
        </tr>

        <tr>
          <th>··· sell</th>
          <UnitBudgetCells
            unit={props.unit}
            value={SellConsultancyWorkingDays}
          />
        </tr>

        <tr>
          <th>Committed</th>
          <UnitBudgetCells unit={props.unit} value={CommittedWorkingDays} />
        </tr>
        <tr>
          <th>Uncommitted</th>
          <UnitBudgetCells unit={props.unit} value={UncommittedWorkingDays} />
        </tr>
      </tbody>
    </table>
  );
};

const UnitBudgetCells: React.FC<{
  unit: string;
  value: React.FC<{ unitBudget: string }>;
}> = props => {
  const periods = useStore(s => s.listFuturePeriods());
  return (
    <>
      {periods
        .map(p => UnitBudgetId.from(props.unit, p))
        .map((b, i) => (
          <Fragment key={i}>
            <td style={{ textAlign: 'right' }}>
              <props.value unitBudget={b} />
            </td>
          </Fragment>
        ))}
    </>
  );
};
