import create from 'zustand';
import { persist } from 'zustand/middleware';
import { StoreBuilder } from './utils/store-builder';
import * as Game from './factories/game';
import * as Logger from './factories/logger';
import * as Player from './factories/player';
import * as ProjectsDirectory from './factories/projects-directory';
import * as ProjectsPortfolio from './factories/projects-portfolio';
import * as CompanyUnits from './factories/company-units';
import * as UnitBudgetTransfers from './factories/unit-budget-transfers';
import * as Budgets from './factories/budgets';
import * as Simulations from './factories/simulations';
import * as AccountingLedger from './factories/accounting-ledger';
import * as CostCenters from './factories/cost-centers';

const creator = StoreBuilder.summon()
  .append(Game.create)
  .append(Logger.create)
  .append(Player.create)
  .append(ProjectsDirectory.create)
  .append(ProjectsPortfolio.create)
  .append(CompanyUnits.create)
  .append(UnitBudgetTransfers.create)
  .append(Budgets.create)
  .append(Simulations.create)
  .append(AccountingLedger.create)
  .append(CostCenters.create)
  .toRootSlice();

export const useStore = create(
  persist(creator, {
    name: 'accounting-sim',
  })
);
