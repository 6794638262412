import { useStore } from '../../store';

export const SellConsultancyWorkingDays: React.FC<{
  unitBudget: string;
}> = props => {
  const sell = useStore(s => s.sellWorkingDays);
  const workingDays = useStore(s =>
    s.toMaxSellableWorkingDays(props.unitBudget, 10)
  );

  return (
    <button
      disabled={workingDays === 0}
      onClick={() => sell(props.unitBudget, workingDays)}
    >
      - {workingDays.toString().padStart(3, ' ')}
    </button>
  );
};
