import { Unit_BudgetTable } from '../unit/budget-table';
import { Unit_BudgetUtilization } from '../unit/budget-utilization';

export const UnitBudgetWidget: React.FC<{ unit: string }> = props => {
  return (
    <>
      <div>
        <b>{props.unit}</b>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <Unit_BudgetTable unit={props.unit} />
        </div>

        <div style={{ marginTop: 10 }}>
          <h6>Economic and Working Days Budget On Projects</h6>
          <Unit_BudgetUtilization unit={props.unit} />
        </div>
      </div>
    </>
  );
};
