import produce from 'immer';
import { ifNot, isEqualTo } from '../../../lib/say-it';
import { IBudgets } from '../concepts/budgets';
import { ICompanyUnits } from '../concepts/company-units';
import { IProjectsDirectory } from '../concepts/projects-directory';
import {
  IProjectsPortfolio,
  IProjectsPortfolioInitializer,
} from '../concepts/projects-portfolio';
import { StoreSlice } from '../utils/store-slice';

export const create: StoreSlice<
  IProjectsPortfolio & IPrivate & IProjectsPortfolioInitializer,
  IBudgets & ICompanyUnits & IProjectsDirectory
> = (set, get) => {
  return {
    projectsInPortfolio: [],

    isProjectInPortfolio(id) {
      return get().projectsInPortfolio.includes(id);
    },

    isPortfolioFeasible() {
      return !get().isSomeBudgetOver('');
    },

    listProjectsInPortfolio() {
      return get().projectsInPortfolio;
    },

    includeProjectToPortfolio(projectId) {
      if (get().isProjectInPortfolio(projectId)) return;

      set(
        produce<IPrivate>(s => {
          s.projectsInPortfolio.push(projectId);
        })
      );

      get()
        .listEffortsByProject(projectId)
        .forEach(effort => {
          get().commitUnitBudgetToProjects(effort.unitBudget, effort.economic);

          get().commitProjectsUnitBudget(effort.unitBudget, projectId, {
            'working-days': effort.workingDays,
            economic: effort.economic,
          });
        });
    },

    excludeProjectFromPortfolio(projectId) {
      set(
        produce<IPrivate>(s => {
          s.projectsInPortfolio = s.projectsInPortfolio.filter(
            ifNot(isEqualTo(projectId))
          );
        })
      );

      get()
        .listEffortsByProject(projectId)
        .forEach(effort => {
          if (
            get().readAvailableBudgetOnProjects(effort.unitBudget) >=
            get().readCommittedBudgetOnProjects(effort.unitBudget)
          ) {
            get().releaseUnitBudgetFromProjects(
              effort.unitBudget,
              effort.economic
            );
          }

          get().releaseProjectsUnitBudget(effort.unitBudget, {
            'working-days': effort.workingDays,
            economic: effort.economic,
          });
        });
    },

    initProjectPortfolio() {
      set(
        produce<IPrivate>(s => {
          s.projectsInPortfolio = [];
        })
      );
    },
  };
};

interface IPrivate {
  projectsInPortfolio: string[];
}
