import produce from 'immer';
import { ifValueOf, isEqualTo, to, toSum } from '../../../../lib/say-it';
import { BudgetId } from '../../../model/identifiers/budget-id';
import { IBudgets, IBudgetsInitializer } from '../../concepts/budgets';
import {
  BudgetKind,
  ICompanyUnits,
  ICompanyUnitsInitializer,
} from '../../concepts/company-units';
import { ILogger } from '../../concepts/logger';
import { add, read } from '../../utils/state-operations';
import { StoreSlice } from '../../utils/store-slice';

export const create: StoreSlice<
  ICompanyUnits & IPrivate & ICompanyUnitsInitializer,
  IBudgets & ILogger & IBudgetsInitializer
> = (set, get) => {
  return {
    /** queries */
    listCompanyUnits() {
      return ['finance', 'operations', 'medical', 'commercial'];
    },

    listOverBudgetCompanyUnits() {
      return get()
        .listCompanyUnits()
        .filter(unit => get().isSomeBudgetOver(unit));
    },

    readAvailableUnitBudget(unitBudget) {
      return read(get().availableUnitBudgets, unitBudget);
    },

    readCommittedUnitBudget(unitBudget) {
      return read(get().committedUnitBudgets, unitBudget);
    },

    readUncommittedUnitBudget(unitBudget) {
      return (
        get().readAvailableUnitBudget(unitBudget) -
        get().readCommittedUnitBudget(unitBudget) -
        get().readUnitBudgetTransferred(unitBudget)
      );
    },

    readCommittedUnitBudgetToConsultancy(unitBudget) {
      return read(get().committedUnitBudgetsOnConsultancy, unitBudget);
    },

    readCommittedUnitBudgetToProjects(unitBudget) {
      return read(get().committedUnitBudgetsOnProjects, unitBudget);
    },

    readUnitBudgetTransferred(unitBudget) {
      return get()
        .unitBudgetTransfers.filter(ifValueOf('from', isEqualTo(unitBudget)))
        .map(to('amount'))
        .reduce(toSum(), 0);
    },

    readTotalAvailableWorkingDays(unitBudget) {
      return (
        read(get().personnelWorkingDays, unitBudget) +
        read(get().consultancyWorkingDays, unitBudget)
      );
    },

    readPersonnelWorkingDays(unitBudget) {
      return read(get().personnelWorkingDays, unitBudget);
    },

    readConsultancyWorkingDays(unitBudget) {
      return read(get().consultancyWorkingDays, unitBudget);
    },

    readMaxBuyableWorkingDays(unitBudget) {
      const { workingDays } = get().moneyToWorkingDays(
        get().readUncommittedUnitBudget(unitBudget)
      );
      return workingDays;
    },

    readMaxSellableWorkingDays(unitBudget) {
      const { workingDays } = get().moneyToWorkingDays(
        get().readCommittedUnitBudgetToConsultancy(unitBudget)
      );
      return workingDays;
    },

    toMaxBuyableWorkingDays(unitBudget, workingDaysToBuy) {
      return Math.min(
        workingDaysToBuy,
        get().readMaxBuyableWorkingDays(unitBudget)
      );
    },

    toMaxSellableWorkingDays(unitBudget, workingDaysToSell) {
      return Math.min(
        workingDaysToSell,
        get().readMaxSellableWorkingDays(unitBudget)
      );
    },

    readAvailableBudgetOnProjects(unitBudget) {
      return get().readCommittedUnitBudgetToProjects(unitBudget);
    },

    readCommittedBudgetOnProjects(unitBudget) {
      return get().readBudgetSpentFor(
        BudgetId.fromParts([unitBudget, BudgetKind.Economic])
      );
    },

    readUncommittedBudgetOnProjects(unitBudget) {
      return Math.max(
        0,
        get().readAvailableBudgetOnProjects(unitBudget) -
          get().readCommittedBudgetOnProjects(unitBudget)
      );
    },

    isBudgetOnProjectsOver(unitBudget) {
      return (
        get().readCommittedBudgetOnProjects(unitBudget) >
        get().readAvailableBudgetOnProjects(unitBudget)
      );
    },

    readCommittedWorkingDays(unitBudget) {
      return get().readBudgetSpentFor(
        BudgetId.fromParts([unitBudget, BudgetKind.WorkingDays])
      );
    },

    readUncommittedWorkingDays(unitBudget) {
      return Math.max(
        0,
        get().readTotalAvailableWorkingDays(unitBudget) -
          get().readCommittedWorkingDays(unitBudget)
      );
    },

    readRelCommittedWorkingDays(unitBudget) {
      if (get().readTotalAvailableWorkingDays(unitBudget) === 0) return 0;
      return r2(
        get().readCommittedWorkingDays(unitBudget) /
          get().readTotalAvailableWorkingDays(unitBudget)
      );
    },

    readRelUncommittedWorkingDays(unitBudget) {
      if (get().readTotalAvailableWorkingDays(unitBudget) === 0) return 0;
      return r2(
        get().readUncommittedWorkingDays(unitBudget) /
          get().readTotalAvailableWorkingDays(unitBudget)
      );
    },

    readRelCommittedBudgetOnProjects(unitBudget) {
      if (get().readAvailableBudgetOnProjects(unitBudget) === 0) return 0;
      return r2(
        get().readCommittedBudgetOnProjects(unitBudget) /
          get().readAvailableBudgetOnProjects(unitBudget)
      );
    },

    readRelUncommittedBudgetOnProjects(unitBudget) {
      if (get().readAvailableBudgetOnProjects(unitBudget) === 0) return 0;
      return r2(
        get().readUncommittedBudgetOnProjects(unitBudget) /
          get().readAvailableBudgetOnProjects(unitBudget)
      );
    },

    isWorkingDaysBudgetOver(unitBudget) {
      return (
        get().readCommittedWorkingDays(unitBudget) >
        get().readTotalAvailableWorkingDays(unitBudget)
      );
    },

    moneyToWorkingDays(money) {
      const workingDays = Math.floor(money / 1000);
      return { workingDays, change: money - workingDays * 1000 };
    },

    workingDaysToMoney(workingDays) {
      return workingDays * 1000;
    },

    toMaxCommittableAmount(unit, amount) {
      return Math.min(amount, get().readUncommittedUnitBudget(unit));
    },

    /** commands */
    commitProjectsUnitBudget(unit, activity, effort) {
      Object.keys(effort).forEach(kind => {
        get().useBudget(
          BudgetId.fromParts([unit, kind]),
          activity,
          effort[kind]
        );
      });
    },

    releaseProjectsUnitBudget(unit, effort) {
      Object.keys(effort).forEach(kind => {
        get().freeBudget(BudgetId.fromParts([unit, kind]), effort[kind]);
      });
    },

    transferUnitBudget(from: string, to: string, amount: number) {
      const amountToTransfer = get().toMaxCommittableAmount(from, amount);

      set(
        produce<IPrivate>(s => {
          if (amountToTransfer <= 0) return;
          s.unitBudgetTransfers.push({
            from,
            to,
            amount: amountToTransfer,
          });
        })
      );
    },

    changeMoneyWithWorkingDays(unitBudget, money) {
      const { workingDays, change } = get().moneyToWorkingDays(money);

      set(
        produce<IPrivate>(s => {
          add(s.consultancyWorkingDays, unitBudget, workingDays);
          add(s.committedUnitBudgets, unitBudget, money - change);
        })
      );

      get().log(`bought ${workingDays} working days.`);
    },

    buyWorkingDays(unitBudget, workingDays) {
      const numberOfWorkingDays = get().toMaxBuyableWorkingDays(
        unitBudget,
        workingDays
      );
      const money = get().workingDaysToMoney(numberOfWorkingDays);
      set(
        produce<IPrivate>(s => {
          add(s.consultancyWorkingDays, unitBudget, workingDays);
          add(s.committedUnitBudgets, unitBudget, money);
          add(s.committedUnitBudgetsOnConsultancy, unitBudget, money);
        })
      );

      get().incrementBudget(
        BudgetId.fromParts([unitBudget, BudgetKind.WorkingDays]),
        numberOfWorkingDays
      );
    },

    sellWorkingDays(unitBudget, workingDays) {
      const numberOfWorkingDays = get().toMaxSellableWorkingDays(
        unitBudget,
        workingDays
      );
      const money = get().workingDaysToMoney(numberOfWorkingDays);

      set(
        produce<IPrivate>(s => {
          add(s.consultancyWorkingDays, unitBudget, -numberOfWorkingDays);
          add(s.committedUnitBudgets, unitBudget, -money);
          add(s.committedUnitBudgetsOnConsultancy, unitBudget, -money);
        })
      );

      get().decrementBudget(
        BudgetId.fromParts([unitBudget, BudgetKind.WorkingDays]),
        numberOfWorkingDays
      );
    },

    commitUnitBudgetToProjects(unitBudget: string, amount: number) {
      const increase = get().toMaxCommittableAmount(unitBudget, amount);

      set(
        produce<IPrivate>(s => {
          add(s.committedUnitBudgets, unitBudget, increase);
          add(s.committedUnitBudgetsOnProjects, unitBudget, increase);
        })
      );

      get().incrementBudget(
        BudgetId.fromParts([unitBudget, BudgetKind.Economic]),
        increase
      );
    },

    releaseUnitBudgetFromProjects(unitBudget: string, amount: number) {
      const decrease = Math.min(
        amount,
        read(get().committedUnitBudgetsOnProjects, unitBudget)
      );

      set(
        produce<IPrivate>(s => {
          add(s.committedUnitBudgets, unitBudget, -decrease);
          add(s.committedUnitBudgetsOnProjects, unitBudget, -decrease);
        })
      );
    },

    // PRC = Product Related Costs
    increasePRCBudget(amount: string) {},
    decreasePRCBudget(amount: string) {},

    initCompanyUnits(data) {
      set({
        ...emptyState(),
        personnelWorkingDays: data.personnelWorkingDays,
        availableUnitBudgets: data.availableUnitBudgets,
      });

      get().initBudgets({
        budgets: Object.entries(data.personnelWorkingDays)
          .map(([key, value]) => [
            BudgetId.fromParts([key, BudgetKind.WorkingDays]),
            value,
          ])
          .reduce((hash, [key, value]) => ({ ...hash, [key]: value }), {}),
      });
    },

    /** private */

    ...emptyState(),
  };
};

interface IPrivate {
  availableUnitBudgets: Record<string, number>;
  committedUnitBudgets: Record<string, number>;
  committedUnitBudgetsOnConsultancy: Record<string, number>;
  committedUnitBudgetsOnProjects: Record<string, number>;
  unitBudgetTransfers: BudgetTransfer[];
  personnelWorkingDays: Record<string, number>;
  consultancyWorkingDays: Record<string, number>;
}

type BudgetTransfer = {
  from: string;
  to: string;
  amount: number;
};

const emptyState = (): IPrivate => ({
  availableUnitBudgets: {},
  committedUnitBudgets: {},
  committedUnitBudgetsOnConsultancy: {},
  committedUnitBudgetsOnProjects: {},
  unitBudgetTransfers: [],
  personnelWorkingDays: {},
  consultancyWorkingDays: {},
});

const round = (precision: number = 2) => {
  const factor = Math.pow(10, precision);
  return (target: number) =>
    Math.round((target + Number.EPSILON) * factor) / factor;
};

const r2 = round(2);
