import { UnitBudgetId } from './unit-budget-id';

const PART_SEP = '::';

export const PrjOrgEffortId = {
  from: (project: string, unit: string, period: number) =>
    [project, unit, period].join(PART_SEP),

  fromStruct: (s: ProjectOrgEffortIdStruct) =>
    [s.project, s.unit, s.period].join(PART_SEP),

  toProjectId: (id: string) => id.split(PART_SEP)[0],
  toUnitId: (id: string) => id.split(PART_SEP)[1],
  toPeriod: (id: string) => Number(id.split(PART_SEP)[2]),
  toUnitBudget: (id: string) =>
    UnitBudgetId.from(PrjOrgEffortId.toUnitId(id), PrjOrgEffortId.toPeriod(id)),

  ifHasProjectId: (project: string) => (id: string) =>
    PrjOrgEffortId.toProjectId(id) === project,
};

type ProjectOrgEffortIdStruct = {
  project: string;
  unit: string;
  period: number;
};
