import { UnitBudgetId, UnitId } from '../../model';
import { useStore } from '../../store';
import { kilo, price } from '../../utils/formatters';

export const ReclaimUnitBudget =
  (
    unit: UnitId
  ): React.FC<{
    unitBudget: UnitBudgetId;
  }> =>
  props => {
    const amount = 10_000;
    const reclaim = useStore(s => s.reclaimUnitBudget);

    const sender = unit;
    const period = UnitBudgetId.toPeriod(props.unitBudget);
    const receiver = UnitBudgetId.toUnitId(props.unitBudget);

    return (
      <button onClick={() => reclaim(sender, receiver, period, amount)}>
        - {price(amount, kilo)}
      </button>
    );
  };
