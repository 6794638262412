import { useStore } from '../../store';
import { UnitBudgetWidget } from '../unit.budget-widget';

export const UnitsBudgetDashboard = () => {
  const units = useStore(s => s.listCompanyUnits());

  return (
    <div>
      <h4>Unit Budget Utilization By Period</h4>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flexWrap: 'wrap',
        }}
      >
        {units.map((u, i) => (
          <div key={i} style={{ margin: '5%', width: '35%' }}>
            <UnitBudgetWidget unit={u} />
          </div>
        ))}
      </div>
    </div>
  );
};
