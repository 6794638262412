import { toSum } from '../../../lib/say-it';
import { UnitBudgetId } from '../../model';
import { IBudgetsInitializer } from '../concepts/budgets';
import {
  ICompanyUnits,
  ICompanyUnitsInitializer,
} from '../concepts/company-units';
import { IGame } from '../concepts/game';
import { ILogger } from '../concepts/logger';
import { IPlayer } from '../concepts/player';
import { IProjectsDirectoryInitializer } from '../concepts/projects-directory';
import { IProjectsPortfolioInitializer } from '../concepts/projects-portfolio';
import { IUnitBudgetTransfersInitializer } from '../concepts/unit-budget-transfers';
import { StoreSlice } from '../utils/store-slice';

export const create: StoreSlice<
  IGame & IPrivate,
  ILogger &
    IPlayer &
    ICompanyUnits &
    IProjectsDirectoryInitializer &
    IBudgetsInitializer &
    IProjectsPortfolioInitializer &
    ICompanyUnitsInitializer &
    IUnitBudgetTransfersInitializer
> = (set, get) => {
  return {
    isStarted: true,
    isInitialized: true,
    isStopped: false,

    timeElapsed(now) {
      return 0;
    },
    periods() {
      return [-2, -1, 0, 1, 2, 3];
    },

    init(opts) {
      const projects = Array(30)
        .fill(0)
        .map((v, i) => `prj.${i.toFixed(0).padStart(3, '0')}`);

      const units = ['finance', 'operations', 'medical', 'commercial'];
      const pastPeriods = [-2, -1, 0];
      const futurePeriods = get().listFuturePeriods();

      const numberOfEfforts = nOf(units) * nOf(futurePeriods) * nOf(projects);
      const at = compoundIndex(projects, units, futurePeriods);

      const efforts = {
        workingDays: toRandomBins(3000, 0, 0)(numberOfEfforts),
        economic: toRandomBins(3000, 20, 20)(numberOfEfforts),
      };

      get().initProjectDirectory({
        projects,
        efforts: projects
          .map(project =>
            units
              .map(unit =>
                futurePeriods
                  .map(period => ({
                    project,
                    unit,
                    period,
                    workingDays: efforts.workingDays[at(project, unit, period)],
                    economic:
                      efforts.economic[at(project, unit, period)] * 10_000,
                  }))
                  .flat()
              )
              .flat()
          )
          .flat(),
      });

      get().initCompanyUnits({
        personnelWorkingDays: units
          .map(unit =>
            futurePeriods
              .map(period => ({
                id: UnitBudgetId.from(unit, period),
                amount: Math.floor(100 * (1 + 0.1 * period)),
              }))
              .flat()
          )
          .flat()
          .reduce((hash, v) => ({ ...hash, [v.id]: v.amount }), {}),
        availableUnitBudgets: units
          .map(unit =>
            futurePeriods
              .map(period => ({
                id: UnitBudgetId.from(unit, period),
                amount: 1_000_000,
              }))
              .flat()
          )
          .flat()
          .reduce((hash, v) => ({ ...hash, [v.id]: v.amount }), {}),
      });

      units.forEach(u => {
        futurePeriods.forEach(p => {
          get().buyWorkingDays(
            UnitBudgetId.from(u, p),
            Math.round(Math.random() * 10) * 10 * 0
          );
        });
      });

      get().initProjectPortfolio();
      get().initUnitBudgetTransfers();
    },

    start(time) {},
    stop() {},

    /** */
    listFuturePeriods() {
      return [1, 2, 3];
    },
  };
};

interface IPrivate {
  listFuturePeriods(): number[];
}

const toRandomBins =
  (total: number, min: number, max: number) => (binsCount: number) => {
    const units = new Array(total).fill(0).map(v => 1);
    const deltaSize = max - min;

    return new Array(binsCount)
      .fill(0)
      .map(b => min + Math.random() * deltaSize)
      .reduce(
        (bounds, advance) => [
          ...bounds,
          {
            start: bounds[bounds.length - 1].end,
            end: bounds[bounds.length - 1].end + advance,
          },
        ],
        [{ start: 0, end: 0 }]
      )
      .map(bound => units.slice(bound.start, bound.end).reduce(toSum(), 0));
  };

const nOf = <T>(list: T[]) => list.length;
const iOf =
  <T>(list: T[]) =>
  (v: T) =>
    list.indexOf(v);

const compoundIndex = (...lists: any[][]) => {
  const index = lists.map(iOf);
  const size = lists.map(nOf);

  return (...values: any[]) =>
    values.map((v, i) => index[i](v) + size[i]).reduce(toSum(), 0);
};
