import { useStore } from '../../store';
import { Accounts } from '../../store/factories/accounting-ledger/accounts';

export const CostCenters = () => {
  const units = useStore(s => s.listCompanyUnits());

  return (
    <div>
      <h2>Cost Centers</h2>
      {units.map((u, i) => (
        <CDCReportSetup key={i} unit={u} />
      ))}
    </div>
  );
};

type Entry = [any, IToAmountFn, string?];
interface IToAmountFn {
  (period: number): React.ReactElement;
}

const toEntry = (account: string): Entry => [
  account,
  (period: number) => (
    <AccountAmountBasedOnType account={account} period={period} />
  ),
  account,
];

const CDCReportSetup: React.FC<{ unit: string }> = props => {
  const periods = useStore(s => s.periods());
  const accounts = useStore(s => s.listCCAccounts(props.unit));

  const entries: Entry[] = accounts.map(toEntry);
  return (
    <div>
      <h3>{props.unit}</h3>
      <table style={{ width: 1200, tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ width: '40%' }}>Account</th>
            <th style={{ width: '5%' }}>Cat.</th>
            {periods.map((p, i) => (
              <th key={i}>Y {p}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {entries.map(([id, toAmount, label], i) => (
            <tr key={i}>
              <th>{accountLocalName(label || id)}</th>
              <td style={{ textAlign: 'center' }}>
                <AccountType account={id} />
              </td>
              {periods.map((p, j) => (
                <td key={j} style={{ textAlign: 'right' }}>
                  {toAmount(p)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const AccountAmountBasedOnType: React.FC<{
  account: string;
  period: number;
}> = props => {
  const isDiscretional = useStore(s =>
    s.isCCAccountDiscretional(props.account)
  );
  const isForecast = props.period > 0;
  const amountId = [props.account, props.period].join('::');

  if (isDiscretional && isForecast)
    return <AccountAmountInput account={amountId} />;
  return <AccountTotalAmount account={amountId} />;
};

const AccountAmountInput: React.FC<{ account: string }> = props => {
  const amount = useStore(s => s.readCCAccountValue(props.account));
  const set = useStore(s => s.setCCAccountValue);
  return (
    <input
      style={{ width: '100%', textAlign: 'right' }}
      value={amount}
      onChange={e => {
        set(props.account, Number(e.target.value));
      }}
    />
  );
};

const AccountType: React.FC<{ account: string }> = props => {
  const isDiscretional = useStore(s =>
    s.isCCAccountDiscretional(props.account)
  );
  if (isDiscretional) return <span>D</span>;
  return <span>ND</span>;
};

const accountLocalNameWithoutIndentation = (globalName: string) => {
  const [local, ...ancestors] = globalName.split('/').reverse();
  return `${local}`;
};

const accountLocalName = (globalName: string) => {
  const [local, ...ancestors] = globalName.split('/').reverse();
  return `${ancestors.map(() => '·').join('')} ${local}`;
};

const AccountTotalAmount: React.FC<{
  account: string;
}> = props => {
  const amount = useStore(s => s.readCCAccountValue(props.account));
  return <span>{financial(amount)}</span>;
};

const financial = (amount: number) =>
  `${amount === 0 ? '-' : amount.toFixed(2)} €`;
