import { useStore } from '../../store';

export const SimulationProjectsList: React.FC<{ id: string }> = props => {
  const projects = useStore(s => s.listProjectsInSimulation(props.id));

  return (
    <ul>
      {projects.map((p, i) => (
        <li key={i}>{p}</li>
      ))}
    </ul>
  );
};
