import { UnitBudgetId, UnitId } from '../../model';
import { useStore } from '../../store';
import { financial, mega } from '../../utils/formatters';

export const UnitBudgetReceivedFrom =
  (
    unit: UnitId
  ): React.FC<{
    unitBudget: UnitBudgetId;
  }> =>
  props => {
    const amount = useStore(s =>
      s.readUnitBudgetTransferredTo(props.unitBudget, unit)
    );
    return <span>{financial(amount, mega)}</span>;
  };
